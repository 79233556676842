import React from 'react'
import { Amplify } from 'aws-amplify'
import './App.css';
import '@aws-amplify/ui-react/styles.css';
import awsExports from "./aws-exports";
import {
  Authenticator,
  useAuthenticator,
  CheckboxField
} from '@aws-amplify/ui-react';
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';
import { I18n } from 'aws-amplify/utils';
import { L10n } from '../src/lib/l10n';
import PostList from './pages/PostList';
import NewPost from './pages/NewPost';
import Edit from './pages/Edit';

Amplify.configure(awsExports);

I18n.setLanguage('ja');
I18n.putVocabularies(L10n);

function App() {
  return (
    <Authenticator
      initialState="signUp"
      components={{
        SignUp: {
          FormFields() {
            const { validationErrors } = useAuthenticator();
            return (
              <>
                {/* Re-use default `Authenticator.SignUp.FormFields` */}
                <Authenticator.SignUp.FormFields />
                {/* Append & require Terms & Conditions field to sign up  */}
                <CheckboxField
                  errorMessage={validationErrors.acknowledgement}
                  hasError={!!validationErrors.acknowledgement}
                  name="acknowledgement"
                  value="yes"
                  label={
                    <label>
                      <a
                        href='https://tennispective.com/terms-of-service.html'
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        利用規約
                      </a>と
                      <a
                        href='https://tennispective.com/privacy-policy.html'
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        プライバシーポリシー
                      </a>に同意します。
                    </label>
                  }
                />
              </>
            );
          },
        },
      }}
      services={{
        async validateCustomSignUp(formData) {
          if (!formData.acknowledgement) {
            return {
              acknowledgement: '利用規約とプライバシーポリシーに同意する必要があります。',
            };
          }
        },
      }}
    >
      {({ signOut, user }) => (
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<PostList username={user.username} signOut={signOut}/>} />
            <Route path="/new_post" element={<NewPost username={user.username} signOut={signOut}/>} />
            <Route path="/edit/:times" element={<Edit username={user.username} signOut={signOut}/>} />
          </Routes>
        </BrowserRouter>
      )}
    </Authenticator>
  );
}

export default App;
