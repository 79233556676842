import {Component} from 'react'
import {
  Grid,
  View,
  Flex,
  Text,
  TextField,
  TextAreaField,
  Button
} from '@aws-amplify/ui-react';
import { generateClient } from 'aws-amplify/api';
import * as mutations from '../graphql/mutations';
import { listPosts } from '../graphql/queries'
import Header from '../components/Header';

class NewPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: props.username,
      times: 0,
      lastWeekTodo: "",
      thisWeekTry: "",
      thisWeekFeedback: "",
      nextWeekTodo: "",
      usualMenu: ""
    }

    this.signOut = props.signOut;
    this.handleChange = this.handleChange.bind(this);
    this.addPost = this.addPost.bind(this);
    this.getAllPosts = this.getAllPosts.bind(this);
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  async addPost() {
    const client = generateClient();
    const newPost = {
      username: this.state.username,
      times: this.state.times,
      lastWeekTodo: this.state.lastWeekTodo,
      thisWeekTry: this.state.thisWeekTry,
      thisWeekFeedback: this.state.thisWeekFeedback,
      nextWeekTodo: this.state.nextWeekTodo,
      usualMenu: this.state.usualMenu
    };
    await client.graphql({
      query: mutations.createPost,
      variables: { input: newPost },
      authMode: 'userPool'
    }).catch((err) => {
      alert("時間を置いて再度お試しください")
    });

    // 画面遷移時に再レンダリングが必要
    // https://dev.classmethod.jp/articles/fix-the-wrong-implementation-of-page-transitions-in-react-app/
    window.location.href = "/"
  }

  async getAllPosts() {
    const client = generateClient();
    const result = await client.graphql({
      query: listPosts,
      variables: {
        username: this.state.username
      },
      // 認可の参考記事
      // https://docs.amplify.aws/javascript/build-a-backend/graphqlapi/customize-authorization-rules/
      authMode: 'userPool'
    });
    const allPosts = result.data.listPosts.items;

    // 今回登録するtimesを登録する
    this.setState({times: allPosts.length+1});
    // 最新の投稿を取得し、前週の投稿を一部項目にセットする
    for (let i=0; i<allPosts.length; i++) {
      if (allPosts[i]["times"] === allPosts.length) {
        // 来週の強化項目
        this.setState({lastWeekTodo: allPosts[i]["nextWeekTodo"]});
        // いつものトレーニング
        this.setState({usualMenu: allPosts[i]["usualMenu"]});
        break;
      }
    }
  }

  componentDidMount() {
    this.getAllPosts();
  }

  render() {
    return (
      <View>
        <Header signOut={this.signOut}/>
        <Grid as="form" rowGap="15px" columnGap="15px" padding="20px">
          <h1>新規投稿</h1>
          <Flex>
            <TextField
              name="times"
              readOnly="readonly"
              width="25%"
              onChange={this.handleChange}
              value={this.state.times}
            /><Text>週目</Text>
          </Flex>
          <TextAreaField
            label="前週の最後に決めた強化項目（※任意）"
            name="lastWeekTodo"
            onChange={this.handleChange}
            value={this.state.lastWeekTodo}
            placeholder="初投稿時は記入の必要なし（書きたい場合は書いてよい）"
          ></TextAreaField>
          <TextAreaField
            label="今週試したこと（※任意）"
            name="thisWeekTry"
            onChange={this.handleChange}
            value={this.state.thisWeekTry}
            placeholder="実績やマッチしたい人のイメージ、得意な領域などを記載してください。"
          ></TextAreaField>
          <TextAreaField
            label="今週の気付き（※任意）"
            name="thisWeekFeedback"
            onChange={this.handleChange}
            value={this.state.thisWeekFeedback}
            placeholder="実績やマッチしたい人のイメージ、得意な領域などを記載してください。"
          ></TextAreaField>
          <TextAreaField
            label="来週の強化テーマを1つ（※任意）"
            name="nextWeekTodo"
            onChange={this.handleChange}
            value={this.state.nextWeekTodo}
            placeholder="サーブやストロークの強化、ネットへの出方を洗練させる、など。"
          ></TextAreaField>
          <TextAreaField
            label="いつものトレーニング（※任意）"
            name="usualMenu"
            onChange={this.handleChange}
            value={this.state.usualMenu}
            placeholder="サーブやストロークの強化、ネットへの出方を洗練させる、など。"
          ></TextAreaField>
          <Button variation="primary" onClick={this.addPost}>登録</Button>
        </Grid>
      </View>
    )
  }
}

export default NewPost;