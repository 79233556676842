import { Component } from 'react'
import {
  Menu,
  MenuItem,
} from "@aws-amplify/ui-react";
import {
  Link
} from "react-router-dom";

class Header extends Component {
  constructor(props) {
    super(props);
    this.signOut = props.signOut;
  }

  render() {
    return (
      <Menu
        menuAlign="start"
        size="large"
      >
        <MenuItem>
          <Link to="/">投稿一覧</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/new_post">新規投稿</Link>
        </MenuItem>
        <MenuItem>
          <Link
            to="https://note.com/tennispective/n/nd64844ccdc5d"
          >菊池のNote</Link>
        </MenuItem>
        <MenuItem>
          <Link
            to="https://docs.google.com/forms/d/e/1FAIpQLSdvItSU4HkFNerUwycDoy76_W-Bt8vRiMTXLjNykulzYk7Aqg/viewform?usp=sf_link"
          >お問い合わせ</Link>
        </MenuItem>
        <MenuItem onClick={() => alert('comming soon!!')}>
          使い方
        </MenuItem>
        <MenuItem onClick={this.signOut}>
          ログアウト
        </MenuItem>
      </Menu>
    )
  }
}

export default Header;