export const L10n = {
  ja: {
    'Sign In': 'ログイン',
    'Sign Up': 'サインアップ',
    'Sign Out': 'サインアウト',
    'Sign in to your account': 'サインイン',
    'Username *': 'ユーザー名 *',
    'Email Address *': 'メールアドレス *',
    Password: 'パスワード *',
    'Confirm Password': 'パスワードを再入力 *',
    'New password': '新しいパスワード *',
    'Confirmation Code': 'セキュリティコード *',
    'Verification code': 'セキュリティコード *',
    'Enter your username': 'ユーザー名を入力',
    'Enter your Email': 'example@tennsipective.com',
    'Enter your password': 'パスワードを入力',
    'Enter your new password': '新しいパスワードを入力',
    'Enter your code': 'コードを入力',
    'Enter code': 'コードを入力',
    'No account?': 'アカウントがありませんか？',
    'Forgot your password?': 'パスワードをお忘れですか？',
    'Reset password': 'パスワードをリセット',
    'Create account': 'アカウントを作成',
    'Forgot Password': 'パスワードを忘れた',
    'Change Password': 'パスワードを変更',
    'New Password': '新しいパスワード',
    'Phone Number': '電話番号',
    'Confirm a Code': 'コードを確認',
    'Confirm Sign In': 'サインインを確認',
    'Confirm Sign up': 'サインアップを確認',
    'Back to Sign In': 'サインインに戻る',
    'Send Code': 'コードを送信',
    'Resend Code': 'コードを再送',
    Email: 'メールアドレス *',
    Confirm: '確認',
    Submit: '送信',
    Change: '変更',
    Skip: 'スキップ',
    Verify: 'コードを送信',
    'Verify Contact': '連絡先を検証',
    'Lost your code?': 'コードがありませんか？',
    'Invalid phone number format': '不正な電話番号フォーマットです。 電話番号は次のフォーマットで入力してください: +819012345678',
    'Create Account': 'アカウントを作成',
    'Have an account?': 'アカウントをお持ちですか？',
    'Sign in': 'サインイン',
    'Create a new account': '新しいアカウントを作成',
    'Reset your password': 'パスワードをリセット',
    'User does not exist.': 'アカウントが存在しません',
    'Incorrect username or password.': 'メールアドレスまたはパスワードが違います',
    'User is not confirmed.': 'セキュリティコードによるアカウント認証がされていません',
    'User already exists': 'アカウントは既に存在します',
    'Invalid verification code provided, please try again.': '入力されたセキュリティコードが無効です。もう一度お試しください',
    'Invalid password format': 'パスワードのフォーマットが不正です',
    'Account recovery requires verified contact information': '本人確認のためメールに記載されたセキリティコードを入力してください',
    'An account with the given email already exists.': 'そのメールアドレスは既に存在します',
    'Username cannot be empty': 'メールアドレスは必須です',
    'Password cannot be empty': 'パスワードは必須です',
    'Phone number cannot be empty': '携帯電話番号は必須です',
    'Confirmation code cannot be empty': 'セキュリティコードは必須です',
    'Password attempts exceeded': 'パスワード試行回数が超過しました',
    'Attempt limit exceeded, please try after some time.': '試行制限を超過しました。しばらくしてからもう一度お試しください',
    'Username/client id combination not found.': 'アカウントが存在しません',
    'CUSTOM_AUTH is not enabled for the client.': 'パスワードは必須です',
    'Invalid email address format.': 'メールアドレスの形式が正しくありません',
    'Invalid phone number format.': '携帯電話番号の形式が正しくありません',
    'Password did not conform with policy: Password not long enough': 'パスワードは8文字以上を入力してください (8文字以上の大文字小文字を含む英数字)',
    'Password does not conform to policy: Password not long enough': 'パスワードは8文字以上を入力してください (8文字以上の大文字小文字を含む英数字)',
    'Password did not conform with policy: Password must have uppercase characters': 'パスワードには大文字を含めてください (8文字以上の大文字小文字を含む英数字)',
    'Password does not conform to policy: Password must have uppercase characters': 'パスワードには大文字を含めてください (8文字以上の大文字小文字を含む英数字)',
    'Password did not conform with policy: Password must have lowercase characters': 'パスワードには小文字を含めてください (8文字以上の大文字小文字を含む英数字)',
    'Password does not conform to policy: Password must have lowercase characters': 'パスワードには小文字を含めてください (8文字以上の大文字小文字を含む英数字)',
    'Password did not conform with policy: Password must have numeric characters': 'パスワードには数字を含めてください (8文字以上の大文字小文字を含む英数字)',
    'Password does not conform to policy: Password must have numeric characters': 'パスワードには数字を含めてください (8文字以上の大文字小文字を含む英数字)',
    'Password does not conform to policy: Password must have symbol characters': 'パスワードには記号を含めてください (8文字以上の大文字小文字を含む英数字記号)',
    "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6": 'パスワードは8文字以上、大文字小文字を含む英数字を指定してください',
    "2 validation errors detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6; Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$": 'パスワードは8文字以上、大文字小文字を含む英数字を指定してください',
    'Invalid session for the user, session is expired.': 'パスワード変更期間を超過しています',
    'Cannot reset password for the user as there is no registered/verified email or phone_number': 'セキュリティコードによるアカウント認証がされていないためパスワードを再設定できません',
    'User is disabled.': '無効なアカウントです', // アカウントをcognitoコンソール上で無効
    'User is already confirmed.': 'アカウントは既に存在します', // セキュリティコード承認後のアカウントに対してセキュリティコード送信をすると発生
    'Incorrect current password.': '現在のパスワードが違います', // 独自error文言。useChangePassword hooks内で'Incorrect username or password.'を変換
    'not authenticated': 'アカウントが認証されていません', // 未認証時のcurrentAuthenticatedUser実行で発生
    'User cannot be confirmed. Current status is CONFIRMED': '現在認証済みなのでアカウントの確認は必要ありません', // 認証済みのアカウントでセキュリティコード入力時に発生
    'Internal server error.': 'サーバーエラーが発生しました',
    'User password cannot be reset in the current state.': '初回ログインが完了していない為、アカウントのパスワードをリセットすることはできません', // cognitoコンソールからアカウント発行後、初回ログイン時のパスワード設定を行っていない状態でforgotPassword API(パスワード忘れAPI)を実行すると発生
    'Invalid session for the user, session can only be used once.': 'アカウントのセッションが無効です。セッションは1回しか使用できません。', // cognitoコンソールからアカウント発行後、初回ログイン時の新パスワード設定を連続で複数回行うと発生
  },
};
