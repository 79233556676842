/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPost = /* GraphQL */ `
  query GetPost($username: ID!, $times: Int!) {
    getPost(username: $username, times: $times) {
      username
      times
      lastWeekTodo
      thisWeekTry
      thisWeekFeedback
      nextWeekTodo
      usualMenu
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $username: ID
    $times: ModelIntKeyConditionInput
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPosts(
      username: $username
      times: $times
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        username
        times
        lastWeekTodo
        thisWeekTry
        thisWeekFeedback
        nextWeekTodo
        usualMenu
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
