/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      username
      times
      lastWeekTodo
      thisWeekTry
      thisWeekFeedback
      nextWeekTodo
      usualMenu
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      username
      times
      lastWeekTodo
      thisWeekTry
      thisWeekFeedback
      nextWeekTodo
      usualMenu
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      username
      times
      lastWeekTodo
      thisWeekTry
      thisWeekFeedback
      nextWeekTodo
      usualMenu
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
